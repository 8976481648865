import React from 'react'
import PropTypes from 'prop-types'
import {Button} from "react-bootstrap";
import "../buttons/buttons.css";

const button = props => {

    return (
        <>
            <Button id={props.id || ''} className={props.classname || 'btn-custom'} variant={props.variant} onClick={props.onClick} disabled={props.disabled || false }>{props.text}</Button>
        </>
    )
}

button.propTypes = {
    text: PropTypes.string,
    customText: PropTypes.string
}

button.defaultProps = {
    text: "Click You"
}

export default button
